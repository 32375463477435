var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',[_c('v-textarea',{ref:"descricao",attrs:{"auto-grow":"","solo":"","clearable":"","color":"indigo","counter":"3000","maxlength":"3000"},on:{"click:clear":function($event){return _vm.custom_clear()},"input":_vm.updateText},model:{value:(_vm.texto),callback:function ($$v) {_vm.texto=$$v},expression:"texto"}}),_c('v-toolbar',{attrs:{"dense":"","short":"","flat":""}},[_c('v-toolbar-title',[_vm._v("Elementos de Texto")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","text":""},on:{"click":_vm.addHeader}},on),[_c('v-icon',[_vm._v("title")])],1)]}}])},[_c('span',[_vm._v("Título")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","text":""},on:{"click":_vm.addBold}},on),[_c('v-icon',[_vm._v("format_bold")])],1)]}}])},[_c('span',[_vm._v("Negrito")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","text":""},on:{"click":_vm.addItalic}},on),[_c('v-icon',[_vm._v("format_italic")])],1)]}}])},[_c('span',[_vm._v("Itálico")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","text":""},on:{"click":_vm.addChain}},on),[_c('v-icon',[_vm._v("strikethrough_s")])],1)]}}])},[_c('span',[_vm._v("Texto Tachado")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","text":""},on:{"click":_vm.addLink}},on),[_c('v-icon',[_vm._v("insert_link")])],1)]}}])},[_c('span',[_vm._v("Hiperligação")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","text":""},on:{"click":_vm.addLine}},on),[_c('v-icon',[_vm._v("minimize")])],1)]}}])},[_c('span',[_vm._v("Linha Horizontal")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","text":""},on:{"click":_vm.addListItem}},on),[_c('v-icon',[_vm._v("format_list_bulleted")])],1)]}}])},[_c('span',[_vm._v("Item de Lista")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","text":""},on:{"click":_vm.addListItemNumbered}},on),[_c('v-icon',[_vm._v("format_list_numbered")])],1)]}}])},[_c('span',[_vm._v("Item de Lista Numerada")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","text":""},on:{"click":_vm.addTable}},on),[_c('v-icon',[_vm._v("table_chart")])],1)]}}])},[_c('span',[_vm._v("Tabela")])]),_c('v-dialog',{attrs:{"width":"50%"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"indigo darken-3",attrs:{"small":"","dark":"","rounded":""}},on),[_c('v-icon',[_vm._v("visibility")]),_vm._v("Pré-visualização")],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("Pré-visualização")]),_c('v-card-text',[_c('p',{staticClass:"text-justify",domProps:{"innerHTML":_vm._s(_vm.compiledMarkdown(this.texto))}})]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"indigo darken-1","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v("Continuar o trabalho")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }